<template>
    <div class='demo'>
      <div style="display: flex;">
          <Headbuttom></Headbuttom>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input></div>
      </div>

      <div class="attheadfrom" v-show="flag">
      <el-form
        :inline="true"
        size="small"
        :model="searchParams"
        class="demo-form-inline"
      >
        <el-form-item label="设备序列号">
          <el-input
            v-model="searchParams.serial_number"
            
          ></el-input>
        </el-form-item>
        <el-form-item label="设置安装位置">
          <el-input
            v-model="searchParams.device_name"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input
            v-model="searchParams.name"
            
          ></el-input>
        </el-form-item>

        <el-form-item label="视频查看时间">
          <el-date-picker
            v-model="searchParams.times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="处理时间">
          <el-date-picker
            v-model="searchParams.times2"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{
            $t("devtable.cx")
          }}</el-button>
        </el-form-item>
      </el-form>
      </div>

      <Tables @handlerow="handlerow"
      @handlpages="handlpages"
      @handleSizeChange="handleSizeChange"
      :tableLabel="tableLabel"
      :tableData="tableData"
      :flags="flags"
      :operation="operation"
      :configs="configs"></Tables>

      <!-- 处理是弹框 -->

      <el-dialog
        title="回放视频"
        :visible.sync="dialogVisible"
        width="30%"
        append-to-body
        :before-close="handleClose">
       
         <video
              id="my-video"
              class="video-js"
              controls
              preload="auto"
              width="640px"
              height="300px"
              poster="MY_VIDEO_POSTER.jpg"
              data-setup="{}"
          >
              <source src="http://vod.caihuapp.com/1d42b872dbaf42f7986c9e856e2cd94c/66da9abfe5bf4627a52b32cd304d07da-ce921bd2abad827bc7852c9103b2158c-hd.mp4" type="video/mp4" />
              <source src="MY_VIDEO.webm" type="video/webm" />
              <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider upgrading to a
              web browser that
              <a href="https://videojs.com/html5-video-support/" target="_blank"
                  >supports HTML5 video</a
              >
              </p>
          </video>



        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="handModl">确 定</el-button>
        </span>
      </el-dialog>


    </div>
</template>
<script src="https://vjs.zencdn.net/7.15.4/video.min.js"></script>
<script>
import Tables from '@/components/table'
import {formatDate} from '../../utils/date'
export default {
    components:{
        Tables
    },
    data(){
        return{
            dialogVisible: false,
            remark:'',
           flag:false,
           searchParams:{
             name:'',
             serial_number:'',
             times:'',
             times2:'',
             alarm_start_time:'',
             alarm_end_time:'',
             handle_start_time:'',
             handle_end_time:''
           },
           UserInfo:JSON.parse(window.localStorage.getItem('user')),
          tableLabel:[
            {
              prop: "start_time",
              label: '开始时间',
              align: "center",
            },
            {
              prop: "device_name",
              label: '设备安装位置',
              align: "center",
            },
            {
              prop: "serial_number",
              label: '设备序列号',
              align: "center",
            },
            {
              prop: "user_name",
              label: '姓名',
              align: "center",
            },
            {
              prop: "end_time",
              label: '结束时间',
              align: "center",
            },
            {
              prop: "state",
              label: '是否成功',
              align: "center",
            },
            
          ],
          operation:{
            width:200,
            data:[
              {
              name: "回放",
                type: "primary",
              },
              
            ]
          },
          tableData:[],
          // 分页数据
          configs: {
            page_index:1,
            pagesize: 10,
            total: 20,
            loading: false, // 加载状态
          },
          flags: {
            show: false, // 多选是否显示
            ishow: true, // 序号是否显示
          },
          v:'',
        }
    },

    mounted(){
      this.GetreDprodata()
    },
    methods:{
      handFromshow () {
        this.flag = !this.flag
      },
      onSubmit(){
        this.GetreDprodata()
      },
      // 列表数据
      GetreDprodata(){

        let data={
          method:'co.ipc.video.list',
          page_index:this.configs.page_index,
          page_size:this.configs.page_size,
          agent_id:this.UserInfo.agent_id,
          name:this.searchParams.name,
          serial_number:this.searchParams.serial_number,
          start_time:new Date(this.searchParams.times[0]).getTime()/1000,
          end_time:new Date(this.searchParams.times[1]).getTime()/1000,
          device_name:this.searchParams.device_name
        }
        console.log(data)
        this.$serve(data).then(res=>{
          if(res.data.code==0){
            this.tableData = res.data.data
            this.configs.total = res.data.count
            this.tableData.forEach(el=>{
               el.start_time = formatDate(new Date(el.start_time * 1000), 'yyyy-MM-dd hh:mm')
               el.end_time = formatDate(new Date(el.end_time * 1000), 'yyyy-MM-dd hh:mm')
               el.state ==1 ? el.state = '是':"否"
            })
            
          }
        })
      },
      handlerow(v,name){
        console.log(v)
        if(name=="回放"){
          this.v = ""
          this.dialogVisible = true
          this.v = v
        }
      },
      // 处理
      handModl(){   
          let data={
             method:'co.ipc.alarm.handle',
             agent_id:this.UserInfo.agent_id,
             device_id:this.v.device_id,
             remark:this.remark,
             manager_id:this.UserInfo.manager_id,
             id:this.v.id
           }
           this.$serve(data).then(res=>{
             if(res.data.code==0){
                this.$message({
                  type: "success",
                  message:"处理成功",
                })
                this.GetreDprodata();
             }else{
                this.$message({
                  type: "error",
                  message:"处理失败",
                });
             }
           })
           this.dialogVisible =false
      },
      handleClose(){
        this.dialogVisible = false
      },
      handlpages(v){
        this.configs.page_index = v
        this.GetreDprodata()
      },
      handleSizeChange(){

      }
    }
}
</script>
<style scoped>
.demo{
    background-color: #fff;
    padding: 20px;
    
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
  .video-js{
    width: 400px;
    height: 200px;
  }
</style>